import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TopicsProvider as Provider } from '@shapeable/ui';

const TOPICS_QUERY = graphql`
  query TopicsQuery {
    platform {
      allTopics {
        id path slug name outlineNumber __typename
        color { name value }
        trend { id slug }
      }
    }
  }
`;

export const TopicsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(TOPICS_QUERY), 'allTopics' ) }>{children}</Provider>;
};
