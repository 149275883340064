import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Catalyst } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellHeader, Icon, Image, ImageEntity, ResponsiveContainer, useEntity, useLang, useLink } from '@shapeable/ui';
import { CatalystIcon } from '../elements/catalyst-icon';
import { classNames, entityLabel } from '@shapeable/utils';

const cls = classNames('catalyst-card');

// -------- Types -------->

export type CatalystCardProps = Classable & HasChildren & { 
  entity?: Catalyst;
};

export const CatalystCardDefaultProps: Omit<CatalystCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type TitleProps = {
  hasLink?: boolean;
}
// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    min-height: 200px;
    overflow: hidden;
    min-height: 330px;
    background: #FFF;
  `,
});

const IconContainerStyles = breakpoints({
  base: css`
    position: absolute;
    top: 20px;
    left: 20px;
  `,
});

const ImageContainerStyles = breakpoints({
  base: css`
    &:hover {
      filter: brightness(1.1);
    }
  `,
});

const ImageStyles = breakpoints({
  base: css`
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)};
    border-top: 2px solid dark;
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(1)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-size: ${theme.FONT_SIZE(18)};
    font-weight: 500;
    line-height: 1.25em;
    margin-bottom: 0;
    color: ${theme.COLOR('dark')};
    background: none;
    margin-top: 0;
    margin-bottom: ${theme.UNIT(2)};

    ${({ hasLink }: TitleProps ) => hasLink && css`
      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
    `}
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    ImageContainer: styled(ResponsiveContainer)`${ImageContainerStyles}`,
      Image: styled(ImageEntity)`${ImageStyles}`,

    IconContainer: styled(Icon)`${IconContainerStyles}`,

    Content: styled.div`${ContentStyles}`,
    Header: styled(CellHeader)`${HeaderStyles}`,
    Title: styled.span<TitleProps>`${TitleStyles}`,
};

export const CatalystCard: Shapeable.FC<CatalystCardProps> = (props) => {
  const { className, children, entity } = props;

  const { name } = entity;

  const Icon = CatalystIcon;

  const t = useLang();

  const { Link } = useLink();
  const href = entity.path || '';

  const hasTitleLink = !!href;

  const titleElement = <My.Title hasLink={hasTitleLink}>{name}</My.Title>;

  const label = t(entityLabel(entity));
  
  return (
   <My.Container className={cls.name(className)}>
      <Link href={href} >
      <My.ImageContainer>
        <My.IconContainer color='light' size={64}><Icon /></My.IconContainer>
        <My.Image entity={entity.openGraph} />
      </My.ImageContainer>
      </Link>
      <My.Content>
      <My.Header>{label}</My.Header>
      <Link style={{ textDecoration: 'none' }} href={href}>{titleElement}</Link> 
      </My.Content>
   {children}
   </My.Container>
  )
};

CatalystCard.defaultProps = CatalystCardDefaultProps;
CatalystCard.cls = cls;