import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ExplorerTabsSpec, SliceLayout, useView } from '@shapeable/ui';

import { ExplorerCommodityGroupsLayout } from './explorer-commodity-groups-layout';
import { ExplorerSummaryLayout } from './explorer-summary-layout';
import { ExplorerThemesLayout } from './explorer-themes-layout';
import { ExplorerInnovationsLayout } from './explorer-innovations-layout';
import { ExplorerTabSpec, ExplorerTabs } from './explorer-tabs';
import { ExplorerCommunityLayout } from './explorer-community-layout';
import { ExplorerMarketMapLayout } from './explorer-market-map-layout';
import { ExplorerLibraryLayout } from './explorer-library-layout';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { WITH_AUTHENTICATION_REQUIRED_OPTIONS } from '../../data';
import { ExplorerImpactStrategies } from './explorer-impact-strategies';
import { classNames } from '@shapeable/utils';
import { SiteFooterLayout } from '@shapeable/web';
import { AiCIcon, AiUpperCaseIconGlyph, ConnectionsNavigatorIconGlyph, GridIconGlyph, IconSheet } from '@shapeable/icons';
import { ExplorerHomeLayout } from './explorer-home-layout';
import { GptLayout } from './gpt-layout';
const cls = classNames('explorer-layout');

// Move to UI package

// -------- Types -------->

export type ExplorerView = 'copperAi' | 'explorer' | 'value-chain' | 'commodities' | 'impact-strategies' | 'community' | 'innovations' | 'stakeholder-map' | 'library';

export type ExplorerGridLayoutProps = Classable & HasChildren & { 
  entity: Entity;
};

export const ExplorerGridLayoutDefaultProps: Omit<ExplorerGridLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: ${theme.UNIT(10)};
  `,
});

const TabsStyles = breakpoints({
  base: css`
   
  `,
});

const ViewChildStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});



const CommodityGroupsStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const InnovationsStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ExplorerStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ThemesStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ViewStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: ${theme.COLOR('bright-warm')};
  `,
});

const FooterLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
    View: styled.div`${ViewStyles}`,
      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
      Explorer: styled(ExplorerHomeLayout).attrs(cls.attr('summary'))`${ExplorerStyles}`,
      Commodities: styled(ExplorerCommodityGroupsLayout).attrs(cls.attr('commodities'))`${CommodityGroupsStyles}`,
      Innovations: styled(ExplorerInnovationsLayout).attrs(cls.attr('innovations'))`${InnovationsStyles}`,
      ValueChain: styled(ExplorerThemesLayout).attrs(cls.attr('value-chain'))`${ThemesStyles}`,
      ImpactStrategies: styled(ExplorerImpactStrategies).attrs(cls.attr('impact-strategies'))`${ViewChildStyles}`,
      Community: styled(ExplorerCommunityLayout).attrs(cls.attr('community'))`${ViewChildStyles}`,
      MarketMap: styled(ExplorerMarketMapLayout).attrs(cls.attr('market-map'))`${ViewChildStyles}`,
      Library: styled(ExplorerLibraryLayout).attrs(cls.attr('library'))`${ViewChildStyles}`,

      Footer: styled(SiteFooterLayout)`${FooterLayoutStyles}`,

};

export const ExplorerGridLayoutComponent: Shapeable.FC<ExplorerGridLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { showView, activeView, setActiveView } = useView<ExplorerView>('explorer', 'explorer');

  const tabs: ExplorerTabsSpec<ExplorerView>[] = [
    {
      id: 'copperAi',
      icon: <AiUpperCaseIconGlyph size={24} />
    },
    {
      id: 'explorer',
      label: 'Explorer',
      icon: <ConnectionsNavigatorIconGlyph />
    },
    {
      id: 'value-chain',
      label: 'Value Chain',
      icon: <GridIconGlyph />
    },
    {
      id: 'innovations',
      label: 'Innovations',
      icon: <GridIconGlyph />
    },
    {
      id: 'impact-strategies',
      label: 'Impact Strategies',
      icon: <GridIconGlyph />
    },
    {
      id: 'community',
      label: 'Community',
      icon: <GridIconGlyph />
    },
    {
      id: 'stakeholder-map',
      label: 'Stakeholder Map',
      icon: <GridIconGlyph />
    },
    {
      id: 'library',
      label: 'Library',
      icon: <GridIconGlyph />
    },
  ];

  return (
    <>
    <SliceLayout boundary='bleed'> 
    <My.Container className={cls.name(className)}>
      <My.Tabs tabs={tabs} />
      <My.View>
      {
        showView('copperAi') && 
        <My.GptLayout entity={entity} />
      }
      {
        showView('explorer') && 
        <My.Explorer entity={entity} />
      }
      {
        showView('innovations') && 
        <My.Innovations />
      }
      {
        showView('value-chain') && 
        <My.ValueChain />
      }
      {
        showView('impact-strategies') &&
        <My.ImpactStrategies />
      }
      {
        showView('community') &&
        <My.Community />
      }
      {
        showView('stakeholder-map') &&
        <My.MarketMap />
      }
      {
        showView('library') &&
        <My.Library />
      }

      </My.View>
    </My.Container>
    </SliceLayout>
    <My.Footer backgroundColor='#FFFFFF' />
    </>
  )
};

ExplorerGridLayoutComponent.defaultProps = ExplorerGridLayoutDefaultProps;
ExplorerGridLayoutComponent.cls = cls;

export const ExplorerGridLayout = withAuthenticationRequired(ExplorerGridLayoutComponent, WITH_AUTHENTICATION_REQUIRED_OPTIONS);