import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ValueChainNavigator } from '../elements/value-chain-navigator';
const cls = classNames('gpt-aside-layout');

// -------- Types -------->

export type GptAsideLayoutProps = Classable & HasChildren & { 
  
};

export const GptAsideLayoutDefaultProps: Omit<GptAsideLayoutProps, 'entity'> = {

};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-right: ${theme.UNIT(3)};
    margin-bottom: ${theme.UNIT(10)};
  `,
});

const NavigatorStyles = breakpoints({
  base: css`
    max-width: 100%;
    margin-top: ${theme.UNIT(2)};
    height: auto;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Navigator: styled(ValueChainNavigator)`${NavigatorStyles}`,
};

export const GptAsideLayout: Shapeable.FC<GptAsideLayoutProps> = (props) => {
  const { className, children } = props;
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Navigator />
    </My.Container>
  )
};

GptAsideLayout.defaultProps = GptAsideLayoutDefaultProps;
GptAsideLayout.cls = cls;