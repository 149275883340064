import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TrendsProvider as Provider } from '@shapeable/ui';
import { sortBy } from 'lodash';

const TRENDS_QUERY = graphql`
  query TrendsQuery {
    platform {
      allTrends {
        __typename _schema { label pluralLabel }
        id path slug name outlineNumber
        banner { thumbnail { url width height static { childImageSharp { gatsbyImageData } } } }
        openGraph {
          title date description { plain }
          image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            thumbnails { full { url } card { url } }
          }
        }
        color { name value }
        topics { 
          __typename _schema { label pluralLabel }
          id slug name path outlineNumber
          color { name value }
          innovations { 
            __typename _schema { label pluralLabel }
            id name path
          }
          subTopics {
            __typename _schema { label pluralLabel }
            id name path outlineNumber
          }
        }
      }
    }
  }
`;

export const TrendsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(TRENDS_QUERY), 'allTrends' ), 'outlineNumber') }>{children}</Provider>;
};