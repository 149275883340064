import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, HasStyle, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { DottedLine, Icon, useSiteMenuBar, useTheme} from '@shapeable/ui';
import { PulseLoader as Spinner } from 'react-spinners';
import { COLOR_VALUE_MINE_DEVELOPMENT } from '../../theme';
import { classNames } from '@shapeable/utils';

const cls = classNames('tab');

// If both label AND icon are present, the icon will be displayed to the left of the label.

// -------- Types -------->

export type TabVariant = 'grid' | 'section';

export type TabProps = Classable & HasChildren & HasStyle & {
  isActive?: boolean;
  nextIsActive?: boolean;
  isLoading?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: TabVariant;
  count?: number;
  hasIconLabel?: boolean;
  label?: string;
  icon?: React.ReactNode;
  isSticky?: boolean;
}

export const TabDefaultProps: TabProps = {
  isActive: false,
  isLast: false,
  isFirst: false,
  hasIconLabel: false,
  variant: 'grid',
  isSticky: false,
};

// -------- Child Component Props -------->

type ContainerProps = {
  isActive: boolean;
  isLast: boolean;
  variant: TabVariant;
  isSticky: boolean;
  _color: string;
}

type CountProps = {
  count: number;
}

type IconProps = {
  isActive: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    flex-wrap: nowrap;
    box-sizing: border-box;
    font-weight: 400; 
    padding: 0;
    flex-grow: 1;
    color: var(--shapeable-mid-grey, #969696);
    text-align: center;
    font-family: ${theme.FONT('sans')};
    background-color: ${theme.COLOR('light')};
    border: none;
    height: ${theme.UNIT(9)};
    font-size: ${theme.FONT_SIZE(14)}; 
    ${theme.FILL('#969696')};

    &:hover {
      color: ${theme.COLOR('link-hover')};
      ${theme.FILL('link-hover')};
    }

    ${({ variant }: ContainerProps ) => variant === 'section' && css`
        color: ${theme.COLOR('text')};
        height: ${theme.UNIT(15)};
        border-top: 2px dotted transparent;
        border-right: 2px dotted ${theme.COLOR('line-semi-subtle')};
        border-bottom: 2px dotted ${theme.COLOR('line-semi-subtle')};

        &:last-child {
          border-right: none;
        }

        &:hover {
          color: ${theme.COLOR('primary')};
        }
    `}

    ${({ isActive }: ContainerProps ) => isActive && css`
      cursor: default;
      svg {
        ${theme.FILL('dark')};
      }
    `}
    
    ${({ isActive, variant }: ContainerProps ) => isActive && variant === 'section' && css`
      font-weight: 500; 
      cursor: default;
      border-bottom-color: transparent;
      border-top: 2px dotted ${theme.COLOR('line-semi-subtle')};
      color: ${theme.COLOR('dark')};

      &:hover {
        color: ${theme.COLOR('dark')};
        background-color: ${theme.COLOR('light')};
      }
    `}

    ${({ isActive, variant, isSticky }: ContainerProps ) => isActive && variant === 'grid' && !isSticky && css`
      border-radius: ${theme.UNIT(2)} ${theme.UNIT(2)} 0 0;
      background-color: ${theme.COLOR('bright-warm')};
      
      color: ${theme.COLOR('dark')};
      border: none;
      cursor: default;
      font-weight: 500;
      
      
      &:hover {
        color: ${theme.COLOR('dark')};
        background-color: ${theme.COLOR('bright-warm')};
        ${theme.FILL('dark')};
      }
    `
    }

    ${({ isSticky, isActive, _color }: ContainerProps ) => isSticky && isActive && css`
        border-radius: none;
        background-color: ${_color};
        ${theme.FILL('bright')};
        color: ${theme.COLOR('light')};
        .shp--icon, svg {
          ${theme.FILL('light')};
        }

        &:hover {
          color: ${theme.COLOR('light')};
          svg {
            ${theme.FILL('light')};
          }
        }
      `}

    ${({ isSticky, isActive }: ContainerProps ) => isSticky && !isActive && css`
      background-color: ${theme.COLOR('line-mid')};
      color: ${theme.COLOR('mid')};
      &:hover {
        color: ${theme.COLOR('#515151')};
        ${theme.FILL('#515151')};
      }
    `}

  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(14)}; 
     ${({ variant }: ContainerProps ) => variant === 'grid' && css`
      height: ${theme.UNIT(11)};
    `}

    ${({ isSticky }: ContainerProps ) => isSticky && css`
      height: ${theme.UNIT(10)};
      font-size: ${theme.FONT_SIZE(14)};
    `}
  `,

  desktop: css`
    font-size: ${theme.FONT_SIZE(16)}; 
    
    ${({ isSticky }: ContainerProps ) => isSticky && css`
      font-size: ${theme.FONT_SIZE(14)};
    `}
  `,
});

const LabelStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    white-space: nowrap;
    flex-grow: 1;
    padding: 0 ${theme.UNIT(3)};
    gap: ${theme.UNIT(1)};
  `,
});

const TabCountStyles = breakpoints({
  base: css`
    ${({ count }: CountProps ) => (count && css`
      color: var(--shapeable-theme-primary, #FCAF18);
    `
    )}
  `,
});

const DottedLineStyles = breakpoints({
  base: css`
    flex-grow: 0;
    flex-shrink: 1;
  `,
});

const SpinnerStyles = breakpoints({
  base: css`
    
  `,
});

const LabelAndIconStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: ${theme.UNIT(3)};
  `,
});

const IconLabelStyles = breakpoints({
  base: css`
    
  `,
});

const IconStyles = breakpoints({
  base: css`
    ${theme.FILL('#969696')};

  `,
});



// -------- Components -------->

const My = {
  Container: styled.button<ContainerProps>`${ContainerStyles}`,
    Label: styled.span`${LabelStyles}`,
    Spinner: styled(Spinner)`${SpinnerStyles}`,
    DottedLine: styled(DottedLine)`${DottedLineStyles}`,
    TabCount: styled.span<CountProps>`${TabCountStyles}`,

    LabelAndIcon: styled.div`${LabelAndIconStyles}`,
      Icon: styled(Icon)<IconProps>`${IconStyles}`,
      IconLabel: styled.span`${IconLabelStyles}`,
};

export const Tab: Shapeable.FC<TabProps> = (props) => {
  const { className, children, isActive, nextIsActive, onClick, isLast, isFirst, variant, count, isLoading, hasIconLabel, icon, label, isSticky } = props;

  const { color } = useSiteMenuBar();

  const tabContent = hasIconLabel ? (
    <My.LabelAndIcon>
      <My.Icon isActive size={14}>{icon}</My.Icon>
      <My.IconLabel>{label}</My.IconLabel>
    </My.LabelAndIcon>
  ) : (
    children
  );

  return (
    <My.Container onClick={onClick} isActive={isActive} _color={color} variant={variant} isLast={isLast} isSticky={isSticky} className={cls.name(className)}>
      <My.Label>
        {tabContent}
        {
          isLoading ?
          <Spinner color={color} size={6}  />
          : (
            !!count && (variant === 'section' || variant === 'grid') && 
            <My.TabCount count={count}>({count})</My.TabCount>
          )
        }
      </My.Label>
      
      {!isActive && !nextIsActive && !isLast && variant === 'grid' && <My.DottedLine width={2} gap={5} />}
    </My.Container>
  )
};

Tab.cls = cls;
Tab.defaultProps = TabDefaultProps;