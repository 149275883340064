import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Slice } from '@shapeable/types';
import { AspectRatio, breakpoints, from, theme } from '@shapeable/theme';
import { classNames, entityTypeNameFor } from '@shapeable/utils';
import { CellLabel, DottedLine, EntityAsideHeaderDefaultProps, EntityAsideHeaderProps, EntityGridDetailsAsideHeader, EntityGridDetailsNavigationDefaultProps, EntityGridDetailsNavigationProps, EntityMainHeader, EntityMainHeaderDefaultProps, EntityMainHeaderProps, SiteSidebar, SliceLayout, useEntity, useSiteSidebar } from '@shapeable/ui';
const cls = classNames('entity-grid-details-layout');

// -------- Types -------->

export type ExplorerLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
  aside?: React.ReactNode;
  main?: React.ReactNode;
  split?: [number, number];
  asideMaxWidth?: number;
  asideIsRevealed?: boolean; // is the aside currently revealed (for mobile)
  asideHeaderProps?: Omit<EntityGridDetailsNavigationProps, 'entity'>;
  mainHeaderProps?: Omit<EntityMainHeaderProps, 'entity'>;
  aspectRatio?: AspectRatio;
  showSlideControls?: boolean;
  BannerTitles?: React.ReactNode;
};

export const ExplorerLayoutDefaultProps: Omit<ExplorerLayoutProps, 'entity'> = {
  asideHeaderProps: EntityGridDetailsNavigationDefaultProps,
  mainHeaderProps: EntityMainHeaderDefaultProps,
  split: [4.5, 5.5],
  asideMaxWidth: 600,
  aspectRatio: { base: 16 / 9, desktop: 21 / 9 },
  showSlideControls: true,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type AsideProps = {
  _isRevealed?: boolean;
  _maxWidth?: number;
  _percentageWidth?: number;
  _sidebarWidth?: number;
}

type MainProps = {
  _percentageWidth?: number;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;  
  `,
});

const AsideStyles = breakpoints({
  base: css`
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;    
    background: ${theme.COLOR('bright-warm')};
    position: fixed;
    transition: left 0.3s;
    height: 100vh;
    z-index: 500;
    
    ${({ _isRevealed, _sidebarWidth }: AsideProps) => css`
      left: ${_isRevealed ? 0 : '200vw'};
      width: calc(100% - ${_sidebarWidth}px);
      top: 0;
    `}

    ${({ _percentageWidth }: AsideProps) => _percentageWidth && css`
      flex-basis: ${_percentageWidth}%;
    `}

  `,
  desktop: css`
    z-index: 0;
    position: static;
    height: auto;

    
    ${({ _maxWidth }: AsideProps) => _maxWidth && css`
      max-width: ${_maxWidth}px;
    `}
    display: flex;
  `
});

const MainHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const AsideHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const MainStyles = breakpoints({
  base: css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: ${theme.UNIT(1)};
  `,
  desktop: css`
    
    flex-grow: 1;
    
  `,
});

const BannerStyles = breakpoints({
  base: css`
    .shp--image-entity-background,
    .shp--image-entity-background-slideshow {
      overflow: visible;
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
    color: #FFF;
    display: flex;
    justify-self: stretch;
    flex-direction: column;
    justify-content: center;
  `,
});

const LineStyles = breakpoints({
  base: css`
    display: none;
  `,
  desktop: css`
    display: flex;
  `,
});

const AsideLayoutContainerStyles = breakpoints({
  base: css`
    width: 100%;
    top: 130px;
    position: sticky;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Aside: styled.aside<AsideProps>`${AsideStyles}`,
      AsideHeader: styled(EntityGridDetailsAsideHeader).attrs(cls.attr('aside-header'))<AsideProps>`${AsideHeaderStyles}`,
    Main: styled.main<MainProps>`${MainStyles}`,
      Line: styled(DottedLine)`${LineStyles}`,

      AsideLayoutContainer: styled.div`${AsideLayoutContainerStyles}`,
    
};


export const ExplorerLayout: Shapeable.FC<ExplorerLayoutProps> = (props) => {
  const { className, children, aside, main, asideHeaderProps, mainHeaderProps, asideMaxWidth, asideIsRevealed, aspectRatio } = props;
  const entity = useEntity(props.entity);

  const sidebar = useSiteSidebar();

  const entityType = (entityTypeNameFor(entity));
  
  const [ asideWidth, mainWidth ] = props.split?.length === 2 ? props.split : [4, 6];
  const asidePercentageWidth = asideWidth / (asideWidth + mainWidth) * 100;

  return (
   <My.Container className={cls.name(className)}>
      <My.Aside _sidebarWidth={sidebar.width} _isRevealed={asideIsRevealed} _percentageWidth={asidePercentageWidth} _maxWidth={asideMaxWidth}>
        <My.AsideHeader navigationProps={asideHeaderProps} entity={entity} />
        <My.AsideLayoutContainer>
            {aside}
        </My.AsideLayoutContainer>

      </My.Aside>
      {
        asideIsRevealed && 
        <SiteSidebar />
      }
       <My.Main>
        {main || children}
      </My.Main>
   </My.Container>
  )
};

ExplorerLayout.defaultProps = ExplorerLayoutDefaultProps;
ExplorerLayout.cls = cls;