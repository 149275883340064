import React from "react";
import { useActiveEntity, useAppState, useEntity, useLink, useView } from "@shapeable/ui";
import { Page, SubTopic, Topic, Trend } from "@shapeable/types";
import { Innovation } from "@shapeable/copper-connect-types";
import { entityTypeNameFor } from "@shapeable/utils";


export type ValueChainMapState = {
  isShown?: boolean;
};


export const ValueChainDefaultState: ValueChainMapState = {
  isShown: false
};

export type ValueChainMapInfo = {
  state: ValueChainMapState; // no internal state (yet)
  activeTrend: Trend;
  activeTopic: Topic;
  isShown: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
  setActiveTrend: (trend: Trend) => void;
  setActiveTopic: (topic: Topic) => void;
};


export type ValueChainConfig = {
  defaultState: ValueChainMapState;
}

export const ValueChainDefaultConfig: ValueChainConfig = {
  defaultState: ValueChainDefaultState,
};



export const useValueChainMap: () => ValueChainMapInfo = () => {
 
  const [ appState, setAppState, patchAppState ] = useAppState();
  const valueChainMap = appState.valueChainMap || ValueChainDefaultState; 

  const entity = useEntity();

  const { navigate } = useLink();

  const typeName = entityTypeNameFor(entity);

  const show = () => patchAppState({ valueChainMap: { isShown: true } });
  const hide = () => patchAppState({ valueChainMap: { isShown: false } });

  const toggle = () => patchAppState({ valueChainMap: { isShown: !valueChainMap.isShown } }); 

  const activeTopic = 
    (typeName === 'Topic' ? entity : null) || 
    (typeName === 'SubTopic' ? (entity as SubTopic).topic : null);
    (typeName === 'Innovation' ? (entity as Innovation).topic : null);

  const activeTrend = 
    (typeName === 'Trend' ? entity : null) || 
    (typeName === 'Topic' ? (entity as Topic).trend : null) ||
    (typeName === 'SubTopic' ? (entity as SubTopic).topic?.trend : null) || 
    (typeName === 'Innovation' ? (entity as Innovation).topic?.trend : null) ||
    (typeName === 'Page' ? (entity as Page).trend : null);

  return {
    isShown: valueChainMap.isShown,
    state: valueChainMap,
    activeTrend,
    activeTopic,
    toggle,
    show,
    hide,
    setActiveTrend: (trend) => { hide(); navigate(trend.path) },
    setActiveTopic: (topic) => { hide(); navigate(topic.path) },
  }

}